<template>
	<div>
		<template v-if="loadingReferrals">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Referral</h3>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p class="info__address">Referrer Name</p>
						<p class="info__address">Status</p>
						<p class="info__address">Referred Name</p>
						<p class="info__address">Date</p>
						<p class="info__address">Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="referralsLoaded && referrals.length > 0">
							<ReferralItem
								v-for="referral in referrals"
								:key="referral.id"
								:referralData="referral"
								@approvalRequestButton="updateRequestMethod"
							/>
						</template>
						<template v-else-if="referrals.length === 0">
							<p class="mt-5 pl-5">No Referral found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Referrals, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import ReferralItem from './ReferralItem';

export default {
	name: 'Referral',
	components: {
		Loader,
		Pagination,
		ReferralItem
	},
	data() {
		return {
			referralsLoaded: false,
			loadingReferrals: true,
			offset: 0,
			limit: 10,
			baseUrl: 'referrals',
			query: '',
			searchLoaded: true,
			total: 0,
			childApprovalRequestEvent: ''
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllReferrals([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				if (this.childApprovalRequestEvent === 'approvalRequestButtonClicked') {
					this.fetchAllReferrals([this.limit, this.offset]);
					this.childApprovalRequestEvent = '';
				}
				this.loading = false;
				this.loadingReferrals = false;
				this.query = '';
				this.referralsLoaded = true;
				this.total = this.totalReferrals;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllReferrals([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			referrals: 'getReferrals',
			status: 'getStatus',
			totalReferrals: 'getTotalReferrals'
		})
	},
	methods: {
		...mapActions(['fetchAllReferrals']),
		updateRequestMethod(value) {
			this.childApprovalRequestEvent = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 1fr 0.5fr 1fr 1fr 0.8fr !important;
}
</style>
