<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p class="info__email capital">{{ fullName }}</p>
			<p>
				{{ commission.amount | formatCurrency(commission.currency) }}
			</p>
			<p>
				{{ commission.source }}
			</p>
			<p>
				{{ commission.description }}
			</p>
			<p class="info__address">
				<span :class="statusStyle" class="pad">
					{{ commission.is_reconciled === 1 ? 'Reconciled' : 'Not Reconciled' }}
				</span>
			</p>
			<p class="info__email capital">{{ commission.created_at | date }}</p>
			<p>
				<button
					@click="updateApproveRequest"
					class="btn-primary btn-sm"
					:class="updateApproveRequestStyle"
					type="button"
					name="approve"
				>
					Approve
				</button>
				<button
					@click="updateDeclineRequest"
					class="btn-primary btn-sm mt-1"
					:class="updateDeclineRequestStyle"
					type="button"
					name="decline"
				>
					Decline
				</button>
			</p>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';

export default {
	name: 'commissionItem',
	mixins: [form],
	props: {
		commissionData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			commission: this.commissionData,
			itemData: ''
		};
	},
	computed: {
		...mapGetters(['getStatus', 'getCommissionDetail']),
		fullName() {
			if (this.commission.user !== undefined) {
				return `${this.commission.user.firstname} ${this.commission.user.lastname}`;
			}
			return null;
		},
		statusStyle() {
			const isReconciled = this.commission.is_reconciled === 1;
			return isReconciled === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		},
		updateApproveRequestStyle() {
			return ['btn__save--outline'];
		},
		updateDeclineRequestStyle() {
			return ['btn__danger'];
		}
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	},
	methods: {
		...mapActions(['updateCommission']),
		updateApproveRequest() {
			this.commission.is_reconciled = 1;
			const data = {
				is_reconciled: 1,
				id: this.commission.id
			};
			this.update(data);
		},
		updateDeclineRequest() {
			this.commission.is_reconciled = 0;
			const data = {
				is_reconciled: 0,
				id: this.commission.id
			};
			this.update(data);
		},
		update(data) {
			this.updateCommission(data);
			this.approvalEventFunc();
		},
		approvalEventFunc() {
			this.$emit('approvalRequestButton', 'approvalRequestButtonClicked');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 1fr 0.5fr 1fr 1fr 1fr 1fr 1fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
.capital {
	text-transform: capitalize;
}
</style>
