<template>
	<div>
		<template v-if="loadingCommissions">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Commission</h3>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p class="info__address">Investor Name</p>
						<p class="info__address">Amount</p>
						<p class="info__address">Source</p>
						<p class="info__address">Description</p>
						<p class="info__address">Status</p>
						<p class="info__address">Date</p>
						<p class="info__address">Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="commissionsLoaded && commissions.length > 0">
							<CommissionItem
								v-for="commission in commissions"
								:key="commission.id"
								:commissionData="commission"
								@approvalRequestButton="updateRequestMethod"
							/>
						</template>
						<template v-else-if="commissions.length === 0">
							<p class="mt-5 pl-5">No Commission found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Commissions, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import CommissionItem from './CommissionItem';

export default {
	name: 'Commission',
	components: {
		Loader,
		Pagination,
		CommissionItem
	},
	data() {
		return {
			commissionsLoaded: false,
			loadingCommissions: true,
			offset: 0,
			limit: 10,
			baseUrl: 'commissions',
			query: '',
			searchLoaded: true,
			total: 0,
			childApprovalRequestEvent: ''
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllCommissions([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				if (this.childApprovalRequestEvent === 'approvalRequestButtonClicked') {
					this.fetchAllCommissions([this.limit, this.offset]);
					this.childApprovalRequestEvent = '';
				}
				this.loading = false;
				this.loadingCommissions = false;
				this.query = '';
				this.commissionsLoaded = true;
				this.total = this.totalCommissions;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllCommissions([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			commissions: 'getCommissions',
			status: 'getStatus',
			totalCommissions: 'getTotalCommissions'
		})
	},
	methods: {
		...mapActions(['fetchAllCommissions']),
		updateRequestMethod(value) {
			this.childApprovalRequestEvent = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 1fr 0.5fr 1fr 1fr 1fr 1fr 1fr !important;
}
</style>
